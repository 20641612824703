import React from 'react';
import './brand.scss';

const Brand = (props) => {
  return (
    <div className={"brand brand-" + props.size}>
      <span className="brand-green">Per</span>
      <span className="brand-red">co</span>
      <span className="brand-blue">La</span>
      <span className="brand-orange">tt</span>
    </div>
  )
};

export default Brand;
