import React from "react"

import Brand from './brand';
import './header.scss';

const Header = () => (
  <header>
    <div className="header-line">
      <Brand size="lg"/>
    </div>
  </header>
)

export default Header
